import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { type Dispatch, type SetStateAction } from 'react'
import type { Member } from '~/graphql/types'
import Content from '../Content'

export default function ExtendedPreview({
  index,
  setOpen,
  open,
  fields
}: {
  index: string
  setOpen: Dispatch<SetStateAction<string>>
  open: string
  fields: Member
}) {
  return (
    <>
      <section
        onClick={() => setOpen(open === index ? '0' : index)}
        className={clsx(
          open === index ? `col-span-1 md:col-span-2 grid-cols-1 md:grid-cols-2` : `col-span-1 grid-cols-1`,
          'relative gap-4 lg:gap-10 z-20 group grid shadow-lm-basic cursor-pointer'
        )}
      >
        <div className="col-span-1 group relative z-20">
          <div className="flex h-full bg-lm-machine relative justify-end flex-col">
            <div className="bg-lm-beluga h-full relative">
              <LossePlaatjie src={fields.recap?.image} className="h-full object-cover" alt="sector-image" maxWidth={1000} />
            </div>
            <div className="flex flex-row items-center justify-between gap-1 p-4 lg:p-6 lg:py-6">
              <div>
                <h3 className="flex font-secondary text-white font-light items-center gap-1 text-base">{fields.title}</h3>
                <div className="text-iw-jasmine text-lm-cadmium font-secondary font-light text-sm">{fields.recap?.function}</div>
              </div>
              <svg width="11" height="23" viewBox="0 0 11 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.369629 22.547V0.380371L10.3335 10.3443C10.6304 10.6412 10.7971 11.0439 10.7971 11.4637C10.7971 11.8835 10.6304 12.2862 10.3335 12.5831L0.369629 22.547Z"
                  fill="#F58A2E"
                />
              </svg>
            </div>
          </div>
        </div>
        {open === index && (
          <div className="col-span-1 z-10 bg-lm-machine relative pl-4 md:pl-0 pr-4 pb-4 md:py-4 lg:pr-11 lg:py-11 flex justify-between flex-col gap-8">
            <div className="bg-lm-machine absolute w-full md:h-full md:w-[50%] h-[50%] -top-[50%] md:-left-[50%] left-0 md:top-0" />
            <Content className="children-p:text-base children-p:font-main text-white">{fields.recap?.description}</Content>
            <div className="flex flex-col gap-3">
              <div className="text-base font-main font-bold text-white">{fields.recap?.ctatext}</div>
              <div className="flex flex-col gap-3">
                {fields.recap?.phone && (
                  <a
                    className="text-lm-cadmium font-secondary font-light flex flex-row gap-3 items-center hover:underline"
                    target="_blank"
                    rel="noreferrer"
                    href={fields.recap?.phone?.url || '/'}
                  >
                    <svg width="28" height="28" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="11.5" cy="11.5" r="11.5" fill="white" />
                      <path
                        d="M17.0526 13.9011C16.3124 13.9011 15.5874 13.7855 14.9002 13.5587C14.5648 13.4432 14.184 13.5322 13.9636 13.7571L12.6013 14.7859C11.0381 13.9516 10.0377 12.9518 9.21475 11.4L10.2158 10.0699C10.4678 9.8179 10.5581 9.44905 10.4501 9.10358C10.2215 8.412 10.1053 7.68632 10.1053 6.94737C10.1053 6.42504 9.68023 6 9.15791 6H6.94737C6.42504 6 6 6.42504 6 6.94737C6 13.0415 10.9585 18 17.0526 18C17.575 18 18 17.575 18 17.0526V14.8484C18 14.3261 17.575 13.9011 17.0526 13.9011Z"
                        fill="#454545"
                      />
                    </svg>

                    {fields.recap?.phone?.title}
                  </a>
                )}
                {fields.recap?.mail && (
                  <a
                    className="text-lm-cadmium font-secondary font-light flex flex-row gap-3 items-center hover:underline"
                    target="_blank"
                    rel="noreferrer"
                    href={fields.recap?.mail?.url || '/'}
                  >
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0ZM22.8867 19.4272V9.02223L17.6839 14.2247L22.8867 19.4272ZM6.22475 19.6392H21.7756L17.0228 14.8864L15.2114 16.6976C15.1236 16.7852 15.0046 16.8343 14.8805 16.8343H13.1196C13.0582 16.8343 12.9973 16.8223 12.9405 16.7988C12.8837 16.7754 12.8321 16.741 12.7887 16.6976L10.9773 14.8864L6.22453 19.6392H6.22475ZM5.11328 9.02191V19.4275L10.3161 14.2247L5.11328 9.02191ZM22.2253 8.36079H5.775L13.3133 15.8991H14.6869L22.2253 8.36079Z"
                        fill="#FFFFFF"
                      />
                    </svg>
                    {fields.recap?.mail?.title}
                  </a>
                )}
                {fields.recap?.linkedin && (
                  <a
                    className="text-lm-cadmium font-secondary font-light flex flex-row gap-3 items-center hover:underline"
                    target="_blank"
                    rel="noreferrer"
                    href={fields.recap?.linkedin?.url || '/'}
                  >
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14 0C11.2311 0 8.52431 0.821086 6.22202 2.35943C3.91973 3.89777 2.12532 6.08427 1.06569 8.64243C0.00606595 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809206 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C28 10.287 26.525 6.72601 23.8995 4.10051C21.274 1.475 17.713 0 14 0V0ZM9.92896 21.3684H6.87474V11.5297H9.92896V21.3684ZM8.40001 10.1868C8.04851 10.1883 7.70447 10.0855 7.41137 9.89147C7.11828 9.69743 6.88929 9.42086 6.75335 9.09671C6.61741 8.77256 6.58062 8.41538 6.64763 8.07033C6.71465 7.72527 6.88245 7.40783 7.12984 7.15812C7.37723 6.90842 7.6931 6.73766 8.03751 6.66743C8.38193 6.59721 8.73943 6.63067 9.06484 6.76358C9.39024 6.89649 9.66894 7.12289 9.8657 7.41416C10.0625 7.70544 10.1684 8.0485 10.1703 8.4C10.1713 8.87134 9.98559 9.32389 9.65386 9.65872C9.32213 9.99356 8.87133 10.1834 8.40001 10.1868ZM21.3684 21.3684H18.3161V16.5789C18.3161 15.4368 18.294 13.9724 16.7282 13.9724C15.1624 13.9724 14.8861 15.2139 14.8861 16.4961V21.3684H11.8447V11.5297H14.7755V12.8708H14.8179C15.225 12.0971 16.2216 11.2811 17.7082 11.2811C20.7992 11.2811 21.3684 13.3184 21.3684 15.9637V21.3684Z"
                        fill="#FFFFFF"
                      />
                    </svg>
                    {fields.recap?.linkedin?.title}
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  )
}
